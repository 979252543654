export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    align: 'center',
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    width: 200,
    ellipsis: true
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    minWidth: 100
  },
  {
    title: '加油枪编号',
    key: 'gun_num',
    align: 'center',
    minWidth: 100
  },
  {
    title: '油品',
    key: 'type',
    align: 'center'
  },
  {
    title: '抽检油量(L)',
    key: 'quantity',
    align: 'center',
    scopedSlots: {
      customRender: 'quantity'
    }
  },
  {
    title: '抽检结果',
    key: 'status',
    align: 'center',
    // extraType: 'result'
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '抽检时间',
    key: 'time'
  },
  {
    title: '备注',
    key: 'content'
  },
  {
    title: '操作',
    fixed: 'right',
    key: 'actions',
    width: 150,
    scopedSlots: {
      customRender: 'actions'
    }
  }
];

export const rules = {
  site_id: [{ required: true, message: '请输入加油站名称' }],
  tanker_id: [{ required: true, message: '请输入加油机名称' }],
  gun_num: [{ required: true, message: '请输入加油枪编号' }],
  type: [{ required: true, message: '请输入抽检油品' }],
  quantity: [
    { required: true, message: '请输入抽检油量' },
    {
      pattern: /^[0-9]{0,8}([.]{1}[0-9]{0,2}){0,1}$/,
      message: '格式不正确, 最多输入整数八位并且最多保留小数后二位'
    }
  ],
  time: [{ required: true, message: '请输入抽检时间' }],
  status: [{ required: true, message: '请输入抽检状态' }]
};
