import request from '@/utils/request';

export function QueryGasTypeCheck(params) {
  return request({
    url: '/oil_type_check_up/data',
    method: 'get',
    params
  });
}

export function ExportGasTypeCheck(params) {
  return request({
    url: '/oil_type_check_up/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function DelGasTypeCheck(params) {
  return request({
    url: '/oil_type_check_up/delete',
    method: 'GET',
    params
  });
}

export function GasTypeCheckAddForm(params) {
  return request({
    url: '/oil_type_check_up/add_form',
    method: 'GET',
    params
  });
}

export function GetGasTypeCheckRow(params) {
  return request({
    url: '/oil_type_check_up/get_detail',
    method: 'GET',
    params
  });
}

// 获取加油机列表接口
export function GetMachineList(params) {
  return request({
    url: '/index/get_tanker_by_site_id',
    method: 'GET',
    params
  });
}

// 获取加油机油枪接口
export function GetMachineRow(params) {
  return request({
    url: '/index/get_gun_by_tanker_id',
    method: 'GET',
    params
  });
}
